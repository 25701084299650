<template>
  <div class="page-warp individual-page">
        <div class="home-banner row">
            <div class="bg"><img src="../../assets/images/dt@2x.png" /></div>
        </div>
        <div class="row indi-main">
            <div class="row model-title individual-bi">
            <span class="t">服务流程</span>
            </div>
            <div class="row gro-model">
                <div class="row model-img individual-bi"><img src="../../assets/images/fwlc-dt@2x.png" /></div>
                <div class="row model-text">
                    <span>提交资料</span>
                    <span>实名认证</span>
                    <span>税务托管</span>
                    <span>申请开票</span>
                </div>
            </div>
            <div class="row model-title individual-bi mt-20" @click="toPath('/policy')">
            <span class="t">服务人物</span>
            </div>
            <div class="row design-main">
                <div class="design-item">
                    <span class="dm-img"><img src="../../assets/images/design/1.png" /></span>
                    <span class="dm-text">设计服务</span>
                </div>
                <div class="design-item">
                    <span class="dm-img"><img src="../../assets/images/design/2.png" /></span>
                    <span class="dm-text">管理咨询</span>
                </div>
                <div class="design-item">
                    <span class="dm-img"><img src="../../assets/images/design/3.png" /></span>
                    <span class="dm-text">市场推广</span>
                </div>
                <div class="design-item">
                    <span class="dm-img"><img src="../../assets/images/design/4.png" /></span>
                    <span class="dm-text">微商&电商</span>
                </div>
                <div class="design-item">
                    <span class="dm-img"><img src="../../assets/images/design/5.png" /></span>
                    <span class="dm-text">设备租赁</span>
                </div>
                <div class="design-item">
                    <span class="dm-img"><img src="../../assets/images/design/6.png" /></span>
                    <span class="dm-text">网红直播</span>
                </div>
                <div class="design-item">
                    <span class="dm-img"><img src="../../assets/images/design/7.png" /></span>
                    <span class="dm-text">科技服务</span>
                </div>
                <div class="design-item">
                    <span class="dm-img"><img src="../../assets/images/design/8.png" /></span>
                    <span class="dm-text">包工头</span>
                </div>
            </div>
            <div class="row bottom-btns mt-30">
                <van-button round block type="info" @click="toPath('/registerService')" native-type="submitSave">立即注册</van-button>
            </div>
        </div>
  </div>
</template>

<script>
export default {
  watch:{
  },
  data() {
    return {
    };
  },
  computed: {},
  filters:{
  },
  created() {
  },
  mounted() {},
  methods: {
      toPath(url){
        this.$router.push({
          path:url
        })
      },
  },
};
</script>
<style lang="less" scoped>
.individual-page { padding-bottom:50px;}
.individual-bi {
    box-sizing: border-box;
    padding:0px 10px;
}
.indi-main {
    position: relative; z-index: 5;
    background:#fff; margin-top:-40px;
    border-top-left-radius: 20px; border-top-right-radius: 20px;
    padding-top:20px;
}
.gro-model {
    padding:10px 0px; position: relative;
    .model-img {
        img { width: 100%; height: auto;}
    }
    .model-text {
        display: flex; color: #080808; font-size: 12px;
    }
}
.design-main {
    display: flex;
    flex-wrap:wrap; padding-top:10px; color: #080808; font-size: 13px; font-weight: 400;
    .design-item { width: 22%; margin-bottom:10px;}
    img { width: 80%;}
    span { display: block; text-align: center;}
}
</style>